












import Vue from "vue";
import { Component, Prop, Ref } from "vue-property-decorator";
import { InteractPageHeader, InteractPageSection } from "@/types";

@Component({})
export default class CollectionDisplayElement extends Vue {
  @Prop({ required: true }) readonly section!: InteractPageSection;
  @Prop({ required: true }) readonly header!: InteractPageHeader;
}
