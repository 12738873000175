import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators';
import { DemoConfigItem, DemoConfigService } from '@/services/demo-config.service';

import router from '@/router';

@Module({ namespaced: true })
export default class DemoConfig extends VuexModule {
  private demoConfigService = new DemoConfigService();
  demoConfigItem?: DemoConfigItem = undefined;

  configuredVariables?: Array<{ key: string; value: string }>;

  @Mutation
  setConfig(demoConfig: DemoConfigItem) {
    this.demoConfigItem = demoConfig;
  }

  @Mutation
  setConfiguredVariables(configuredVariables: Array<{ key: string; value: string }>) {
    this.configuredVariables = configuredVariables;
  }

  @Action
  async updateConfig(configItem: DemoConfigItem) {
    this.context.commit('setConfig', configItem);

    // check speech vendor
    const speech = configItem.configItems.find((value) => {
      return value.name === 'speech_recognition';
    });

    const layout = configItem.configItems.find((value) => {
      return value.name === 'layout_side';
    });

    const variables = new Array<{ key: string; value: string }>();
    configItem.configItems.map((value) => {
      if (value.name.toLowerCase().startsWith('variable')) {
        variables.push({
          value: value.value,
          key: value.name.substring(9)
        });
      }
    });

    this.context.commit('setConfiguredVariables', variables);

    if (speech) {
      const newPath =
        '/' + speech.value.toLowerCase() + '/' + (layout != null ? layout.value : 'right') + '/' + configItem.id;

      const oldPath = router.currentRoute.path;

      if (oldPath !== newPath) {
        await router.push(newPath);
      }
    } else {
      await router.push('/callminer');
    }
  }

  @Action
  async loadConfigById(configId: string) {
    const config = await this.demoConfigService.getConfig(configId);

    await this.context.dispatch('updateConfig', config);
  }
}
