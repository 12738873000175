














import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { TranscriptionMessageEntry } from '@/store/modules/Transcription';
import TranscriptionMessage from '@/components/TranscriptionMessage.vue';

const transcription = namespace('Transcription');
const toolbarOptions = namespace('ToolbarOption');

@Component({
  components: { TranscriptionMessage }
})
export default class Transcription extends Vue {
  @transcription.State
  messages!: Array<TranscriptionMessageEntry>;

  @toolbarOptions.Action
  setIcon!: (event: { name: string; icon: string }) => void;

  @Watch('messages')
  transcriptChanged(messages: Array<TranscriptionMessageEntry>) {
    if (messages.length === 0) {
      return;
    }

    let icon = 'mdi-emoticon-neutral-outline';
    if (messages[0].sentiment === 1) {
      icon = 'mdi-emoticon-happy-outline';
    } else if (messages[0].sentiment === -1) {
      icon = 'mdi-emoticon-sad-outline';
    }

    this.setIcon({
      name: 'Realtime Speech Transcription',
      icon: icon
    });
  }
}
