import { Client, IMessage } from '@stomp/stompjs';

import SockJS from 'sockjs-client';

import { InteractConfig } from '@/types';

export class InteractSocketApi {
  private readonly apiKey: string;
  private readonly tenantId: string;
  private readonly environment: string;
  private stompClient!: Client;
  private readonly topicPrefix = '/topic/';

  constructor(config: InteractConfig) {
    this.apiKey = config.apiKey;
    this.tenantId = config.tenantId;
    this.environment = config.environment;
  }

  public connect() {
    try {
      if (this.stompClient) {
        this.disconnect().then((value) => {
          console.log('Disconnected');
        });
        delete this.stompClient;
      }

      return new Promise((resolve, reject) => {
        this.stompClient = new Client({
          webSocketFactory() {
            const messagingServer = 'https://gointeract.io/messaging/stomp';
            return new SockJS(messagingServer);
          },
          connectHeaders: this.getHeaders(),
          onConnect: resolve,
          onDisconnect: (receipt) => {
            console.error('onDisconnect', receipt);
          },
          onStompError: (err) => {
            console.error('onStompError', err);
          },
          onWebSocketError: (receipt) => {
            console.error('onWebSocketError', receipt);
          }
        });
        this.stompClient.activate();
      });
    } catch (ex) {
      console.error('context', ex);
    }
  }

  subscribe(topic: string, onmessage: (message: IMessage) => void) {
    if (this.stompClient) {
      return this.stompClient.subscribe(this.getTenantTopic(topic), onmessage, this.getHeaders());
    }
  }

  private getTenantTopic(topic: string) {
    return this.topicPrefix + this.tenantId + topic;
  }

  private getHeaders() {
    return {
      'Application-Key': this.apiKey,
      'Tenant-Id': this.tenantId,
      'Environment-Name': this.environment,
      ack: 'client'
    };
  }

  private async disconnect() {
    await this.stompClient.deactivate();
  }
}
