












































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { InteractConfig } from '@/types';
import { EventService } from '@/services/interact/EventService';
import * as _ from 'lodash';
import StringHelperService from '@/services/string-helper.service';

const ToolbarOptionNS = namespace('ToolbarOption');
const InteractEventsNS = namespace('InteractEvents');
const InteractChatConfigNS = namespace('InteractChatConfig');

@Component({
  components: {}
})
export default class AutoNotes extends Vue {
  loading = false;
  snackbar = false;
  snackbarText = '';
  stringHelperService = new StringHelperService();

  contextData: Map<
    string,
    {
      variableSetName: string;
      values: Array<{
        key: string;
        value: string;
      }>;
    }
  > = new Map<string, { variableSetName: string; values: Array<{ key: string; value: string }> }>();

  constructor() {
    super();
    /*
    const tempData = {
      'customerInfoSet_9b20b206-db85-42eb-92be-0d109119e954': {
        variableSetName: 'customerInfoSet',
        values: [
          { key: 'Agent Skill', value: '' },
          { key: 'Customer ID', value: '' },
          { key: 'Customer Profile', value: '' },
          { key: 'First Name', value: 'Thomas' },
          { key: 'Language', value: 'German' },
          { key: 'Last Name', value: 'Schäfer' },
          { key: 'Phone Number', value: '' },
          { key: 'Target Telephony Queue', value: '' }
        ]
      },
      'customerInfoSet_f402b74f-8108-48e1-903b-f7648a7f8038': {
        variableSetName: 'customerInfoSet',
        values: [
          { key: 'Agent Skill', value: '' },
          { key: 'Customer ID', value: '' },
          { key: 'Customer Profile', value: '' },
          { key: 'First Name', value: 'Manuela' },
          { key: 'Language', value: '' },
          { key: 'Last Name', value: '' },
          { key: 'Phone Number', value: '' },
          { key: 'Target Telephony Queue', value: '' }
        ]
      }
    };

    _.each(tempData, (value, key) => {
      this.contextData.set(key, value);
    });

    */
  }

  @ToolbarOptionNS.Action
  setBadge!: (event: { name: string; badge: number | string }) => void;

  @InteractEventsNS.Action
  addEventService!: (config: {
    tenantId: string;
    apiKey: string;
    environment: 'Dev' | 'Test' | 'Prod';
  }) => Promise<string>;

  @InteractChatConfigNS.State
  config!: InteractConfig;

  @InteractEventsNS.State
  eventService!: EventService;

  copied(event: { value: string }) {
    this.snackbarText = event.value + ' copied to clipboard';
    this.snackbar = true;
  }

  connectEventService(newConfig: InteractConfig) {
    if (!this.eventService) {
      console.warn('No Event Service initialized yet');
      setTimeout(() => {
        this.connectEventService(newConfig);
      }, 500);
    } else {
      console.log('Listen for context at session id', {
        id: '/' + newConfig.chatSessionId + '/variableSetSync'
      });

      /*
      In this context the chatSessionId is the uniqueId for the variable synchronizer,
      So if you want more then one variable set you need to call the register method for all of them with the unique ID
      of the agent assistant
       */
      this.eventService.subscribe('/' + newConfig.chatSessionId + '/variableSetSync', (message) => {
        const eventData = JSON.parse(message.body);
        const context = JSON.parse(eventData.data);

        const newMap = new Map<string, { variableSetName: string; values: Array<{ key: string; value: string }> }>();

        _.each(context, (value, key) => {
          newMap.set(key, value);
        });

        /*
        newMap.forEach((value, key) => {
          value.values = value.values.reverse();
        });
        */
        this.contextData = newMap;

        this.setBadge({
          badge: '!',
          name: 'Context'
        });
      });
    }
  }

  @Watch('config')
  async onConfigChanged(newConfig: InteractConfig) {
    this.connectEventService(newConfig);
  }
}
