import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { GoogleSentimentService } from '@/services/google-sentiment.service';
import twilioService from '@/services/twilio-realtime-speech.service.ts';
import { SpeechEvent } from '@/services/types/speech';
import store from '@/store/index';
import rdsAPI from '@/api/rdsAPI';

export interface TranscriptionMessageEntry {
  from: 'agent' | 'customer';
  message: string;
  sendAt: number;
  sentiment?: number;
  id?: number | string;
  key?: number | string;
}

@Module({ namespaced: true })
export default class Transcription extends VuexModule {
  private sentimentService: GoogleSentimentService = new GoogleSentimentService();

  messages: Array<TranscriptionMessageEntry> = [];

  @Mutation
  pushMessage(message: TranscriptionMessageEntry) {
    const existingMessage = this.messages.find((value) => {
      return value.id === message.id;
    });

    if (existingMessage) {
      if (existingMessage.sendAt < message.sendAt) {
        existingMessage.message = message.message;
      } else {
        console.warn('Order of transcript event was messed up. Ignoring this event');
      }
    } else {
      this.messages.unshift(message);
    }
  }

  @Mutation
  reset() {
    this.messages = new Array<TranscriptionMessageEntry>();
  }

  @Action({ rawError: true })
  async addMessage(message: TranscriptionMessageEntry) {
    try {
      const currentMessages = this.context;

      if (message.key) {
        message.id = message.key;
      }

      if (!message.id) {
        message.id = this.messages.length + 1;
      }

      if (message.sentiment === undefined) {
        const sentiment = await this.sentimentService.getSentiment({
          text: message.message,
          language: 'en'
        });

        let score = 0;
        if (sentiment.score > 0.3) {
          score = 1;
        }
        if (sentiment.score < -0.3) {
          score = -1;
        }

        message.sentiment = score;
      }

      this.context.commit('pushMessage', message);
      await rdsAPI().event(store.state.InteractChatConfig.config.chatSessionId, {
        type: 'sentiment_changed',
        description: 'Sentiment changed',
        additionalDataTyped: {
          value: message.sentiment
        }
      });
    } catch (e) {
      console.log(e);
      debugger;
    }
  }

  @Mutation
  setUpdatedMessage(updateEvent: { message: TranscriptionMessageEntry; index: number }) {
    this.messages[updateEvent.index] = updateEvent.message;
  }

  @Action({ rawError: true })
  updateSentimentOnTranscript(event: SpeechEvent) {
    let found = false;

    this.messages.forEach((value, index) => {
      if (value.message.toLowerCase().includes(event.message!.toLowerCase())) {
        found = true;

        value.sentiment = event.sentiment;

        this.context.commit('setUpdatedMessage', {
          index: index,
          message: value
        });

        let icon = 'mdi-emoticon-neutral-outline';
        if (value.sentiment === 1) {
          icon = 'mdi-emoticon-happy-outline';
        } else if (value.sentiment === -1) {
          icon = 'mdi-emoticon-sad-outline';
        }

        store
          .dispatch('ToolbarOption/setIcon', {
            name: 'Realtime Speech Transcription',
            icon: icon
          })
          .then();
      }
    });

    if (found) {
      console.info('Found message to update sentiment.');
    } else {
      console.warn(`Did not find message to update sentiment with text ${event.message}`);
    }
  }

  @Action({ rawError: true })
  async subscribeToTwilioTranscript() {
    twilioService.getSpeechEvents()?.subscribe((value) => {
      console.log();
      switch (value.name) {
        case 'transcript':
          this.context.dispatch('addMessage', value);
          break;

        case 'start':
          this.context.commit('reset');
      }
    });
  }
}
