
















































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import LoginService, { AuthResponse } from '@/services/interact/LoginService';

const InteractAuthNS = namespace('InteractAuth');
const DemoConfigNS = namespace('DemoConfig');

@Component({
  components: {}
})
export default class Login extends Vue {
  dialog = true;
  accountId = '';
  loginEmail = 'tschaefer@jacada.com';
  loginPassword = 'Jacada2016!';
  valid = false;
  tab = 0;
  loginErrorReason = '';
  alert = false;

  loginService: LoginService | undefined;

  constructor() {
    super();
    try {
      window.chrome.webview.postMessage({
        eventName: 'setWidth',
        width: 600
      });
    } catch {
      this.alert = false;
    }
  }

  async doLogin() {
    this.loginService = new LoginService(
      'democenter_df',
      this.loginEmail,
      this.loginPassword,
      'dfc40229-ac90-4c29-b908-e792e35cbaf6',
      'Dev'
    );
    try {
      const authResp = await this.loginService.auth();
      this.updateAuthResponse(authResp);

      const configId = this.$route.params.configId || '12851';
      this.loadConfigById(configId);
    } catch (e) {
      this.alert = true;
      setTimeout(() => {
        this.alert = false;
      }, 2500);
    }
  }

  @DemoConfigNS.Action
  loadConfigById!: (configId: string) => void;

  @InteractAuthNS.Action
  updateAuthResponse!: (authResponse: AuthResponse) => void;
}
