



































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { InteractConfig } from '@/types';
import { EventService } from '@/services/interact/EventService';
import { not } from 'rxjs/internal-compatibility';

const ToolbarOptionNS = namespace('ToolbarOption');
const InteractEventsNS = namespace('InteractEvents');
const InteractChatConfigNS = namespace('InteractChatConfig');
const messages = namespace('Messages');

@Component({
  components: {}
})
export default class AutoNotes extends Vue {
  loading = false;

  currentChatSessionId = '';

  notes: Array<Note> = new Array<Note>();

  dispositionValues = ['Termination Request', 'Fee Reversal', 'New Offer Accepted'];
  selectedDisposition: Array<string> = [];

  userNoteInput = '';

  buttonLoading = false;
  buttonDisabled = false;
  buttonText = 'End Call';

  constructor() {
    super();
  }

  @ToolbarOptionNS.Action
  setBadge!: (event: { name: string; badge: number | string }) => void;

  @InteractEventsNS.Action
  addEventService!: (config: {
    tenantId: string;
    apiKey: string;
    environment: 'Dev' | 'Test' | 'Prod';
  }) => Promise<string>;

  @InteractChatConfigNS.State
  config!: InteractConfig;

  @InteractEventsNS.State
  eventService!: EventService;

  @messages.Action
  sendMessageWithVariables!: (data: { text: string; variables: any }) => Promise<void>;

  get dispositionValueEntries() {
    return Object.entries(this.dispositionValues);
  }

  addUserNote() {
    this.notes.push({ noteType: 'user', text: this.userNoteInput, systemName: 'AgentAssist' });
    this.userNoteInput = '';
  }

  logCall() {
    this.buttonLoading = true;
    let noteString = '';
    this.notes.forEach((note) => {
      noteString += note.text + '\n';
    });

    let disposition = '';
    this.selectedDisposition.forEach((disp, index) => {
      disposition += `${index ? ', ' : ''}${disp}`;
    });

    this.sendMessageWithVariables({
      text: 'crm_log_call',
      variables: {
        disposition: disposition,
        notes: noteString
      }
    }).then(() => {
      this.buttonText = 'Call Logged';
      this.buttonLoading = false;
      this.buttonDisabled = true;
    });
  }

  connectEventService(newConfig: InteractConfig) {
    if (!this.eventService) {
      console.warn('No Event Service initialized yet');
      setTimeout(() => {
        this.connectEventService(newConfig);
      }, 500);
    } else {
      console.log('Listen for auto notes at session id', {
        id: '/' + newConfig.chatSessionId + '/notes'
      });
      this.eventService.subscribe('/' + newConfig.chatSessionId + '/notes', (message) => {
        const eventData = JSON.parse(message.body);
        const note = eventData.data as Note;
        this.notes.push(note);
        this.setBadge({
          name: 'Notes',
          badge: '!'
        });

        if (note.noteType === 'disposition' || note.noteType === 'system disposition') {
          this.handleDispositionEvent(note);
        }
      });
    }
  }

  handleDispositionEvent(note: Note) {
    const foundValues = this.dispositionValues.filter((value) => value === note.disposition);
    if (foundValues.length === 0 && note.disposition) {
      this.dispositionValues.push(note.disposition);
    }

    if (note.disposition && !this.selectedDisposition.includes(note.disposition)) {
      this.selectedDisposition.push(note.disposition);
    }

    if (this.selectedDisposition.length > 3) {
      this.selectedDisposition.shift();
    }
  }

  @Watch('config')
  async onConfigChanged(newConfig: InteractConfig) {
    this.connectEventService(newConfig);
  }
}

interface Note {
  text: string;
  systemName: string;
  noteType: 'system' | 'user' | 'disposition' | 'system disposition';
  disposition?: string;
}
