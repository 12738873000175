import axios from 'axios';
import ApiConfig from '@/api/apiConfig';

interface RdsEvent {
  type: 'event' | 'sentiment_changed' | 'knowledge_accessed';
  description: string;
  additionalDataTyped: {
    value: number | string;
  };
}

export default () => ({
  event: async function(chatSessionId: string, event: RdsEvent) {
    const response = await axios.get(`${ApiConfig.host}${ApiConfig.rdsPath}?externalSystemId=${chatSessionId}`, {
      headers: ApiConfig.rdsHeaders
    });
    if (response.data[0]) {
      const transactionId: string = response.data[0].id;
      const endpoint = `${ApiConfig.host}${ApiConfig.rdsPath}${transactionId}/event`;
      return axios.post(endpoint, event, {
        headers: ApiConfig.rdsHeaders
      });
    }
  },

  async additionalData(chatSessionId: string, data: {}) {
    const response = await axios.get(`${ApiConfig.host}${ApiConfig.rdsPath}?externalSystemId=${chatSessionId}`, {
      headers: ApiConfig.rdsHeaders
    });

    if (response.data[0]) {
      const transactionId: string = response.data[0].id;
      const endpoint = `${ApiConfig.host}${ApiConfig.rdsPath}${transactionId}/additionalData`;
      return axios.post(endpoint, data, {
        headers: ApiConfig.rdsHeaders
      });
    }
  }
});
