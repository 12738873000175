





























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { RegistrationFormField } from "@/types";
import { namespace } from "vuex-class";

const messages = namespace("Messages");

@Component({})
export default class QuiqRegForm extends Vue {
  @Prop({ required: true }) readonly form!: Array<RegistrationFormField>;

  @messages.Action
  private postQuiqRegistration!: (form: object) => void;

  private filledForm: object = {};
}
