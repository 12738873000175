






















import Vue from "vue";
import { Component, Prop, Ref } from "vue-property-decorator";
import {
  InteractPageHeader,
  InteractPageSection,
  InteractSectionChoice
} from "@/types";
import { namespace } from "vuex-class";

const messages = namespace("Messages");

@Component({})
export default class QuestionUploadPhotosElement extends Vue {
  @Prop({ required: true }) readonly section!: InteractPageSection;
  @Prop({ required: true }) readonly header!: InteractPageHeader;

  @messages.Action
  public uploadResource!: (resource: {
    file: File;
    id: string;
    headerId: string;
  }) => void;

  private files: Array<File> = [];

  private upload() {
    const resource = {
      file: this.files[this.files.length - 1],
      id: this.section.sectionChoices[0].uploadImageList!
        .uploadImageListOptions[this.files.length - 1].id,
      headerId: this.header.id
    };
    console.log(
      "Id in component",
      this.section.sectionChoices[0].uploadImageList!.uploadImageListOptions[
        this.files.length - 1
      ].id
    );

    this.uploadResource(resource);
  }
}
