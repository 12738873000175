interface ApiHeaders {
  Env: string;
  'Tenant-Id': string;
  'Application-Key': string;
  domain?: string;
}

interface ApiConfiguration {
  host: string;
  rdsPath: string;
  rdsHeaders: ApiHeaders;
  iaHeaders?: ApiHeaders;
}

const ApiConfig: ApiConfiguration = {
  host: 'https://gointeract.io',
  rdsPath: '/rds/transactions/V2/',
  rdsHeaders: {
    Env: 'Dev',
    'Tenant-Id': 'democenter_df',
    'Application-Key': '76d5f57b-2884-4b0c-ab45-ef36ee3e7b4e'
  }
};

export default ApiConfig;
