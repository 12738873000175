




import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { TranscriptionMessageEntry } from '@/store/modules/Transcription';
import { NextBestActionEntry } from '@/store/modules/NextBestAction';
import { GuidanceEntry } from '@/store/modules/Guidance';
import axios from 'axios';

const DemoConfigNS = namespace('DemoConfig');

@Component({})
export default class Config extends Vue {
  constructor() {
    super();
  }

  @DemoConfigNS.Action
  loadConfigById!: (configId: string) => void;

  async mounted() {
    const configId = this.$route.params.configId || '12851';

    this.loadConfigById(configId);
  }
}
