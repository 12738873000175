











import Vue from 'vue';
import PopupSnackbar from '@/components/PopupSnackbar.vue';
import { Component } from 'vue-property-decorator';

@Component({ components: { PopupSnackbar } })
export default class App extends Vue {
  private width = window.innerWidth;

  created() {
    window.addEventListener('resize', this.resized);
  }

  destroyed() {
    window.removeEventListener('resize', this.resized);
  }

  private resized() {
    this.width = window.innerWidth;
  }
}
