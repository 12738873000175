
























import { Component, Prop, Vue } from 'vue-property-decorator';
import { TranscriptionMessageEntry } from '@/store/modules/Transcription';

@Component({})
// eslint-disable-next-line no-undef
export default class TranscriptionMessage extends Vue {
  @Prop({ required: true }) readonly message!: TranscriptionMessageEntry;
}
