import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store/index';
import NextBestAction from '@/store/modules/NextBestAction';
import { namespace } from 'vuex-class';

// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace Quiq {
  import any = jasmine.any;

  function init(url: string): void;
  function on(eventName: string, handler: (data: any) => void): void;
  function prepareMessage(text: string, method?: 'replace' | 'prepend' | 'append'): void;
  function prepareMessageTemplate(payload: any): void;
  function prepareSnippet(payload: any): void;
  function resizeWindow(dimensions: { height?: string | number; width?: string | number }): void;
  function getConversation(): any;
}

@Module({ namespaced: true })
export default class QuiqEvents extends VuexModule {
  //State
  private _conversation: any;
  private _conversationId = '';

  //Getters
  get conversation() {
    return this._conversation;
  }

  get conversationId() {
    return this._conversationId;
  }

  //Actions
  @Action
  public subscribeToQuiqAlert() {
    console.log('Initializing Quiq SDK');

    window.Quiq.on('init', (conversation: unknown) => {
      console.log('Initialized the sdk');
      this.context.commit('setConversation', conversation);
      console.log('Conversation: ', conversation);
    });
    window.Quiq.init('https://jacada.goquiq.com');
    window.Quiq.on('conversationAccepted', (data: any) => {
      store.dispatch('NextBestAction/startCallAction').then();
      Quiq.resizeWindow({ height: '80vh' });
      this.context.commit('setConversationId', data.conversationId);
      store
        .dispatch('Messages/beginChat', { name: 'quiq', sendAt: Date.now(), sessionId: data.conversationId })
        .then(() => {
          store
            .dispatch('Messages/sendMessageWithVariables', {
              text: 'hello',
              variables: {
                QuiqConversationId: data.conversationId
              }
            })
            .then();
        });
    });
    window.Quiq.on('messageReceived', (data: { text: string }) => {
      console.log('Message', data);
      store.dispatch('NextBestAction/customerQuestion', data.text).then();
    });
    window.Quiq.on('conversationStatusChanged', (data: any) => {
      console.log('Conversation status changed:', data);
    });
    window.Quiq.on('selectedConversationChanged', (data: { conversationId: string }) => {
      console.log('Selected conversation changed:', data.conversationId);
      console.log('My conversation is:', this.conversationId);
      if (data.conversationId === this.conversationId) {
        //Run a flow
        store.dispatch('Messages/invokeFlow', 'a25e77081d5c-011579e2d05a622b-d88b').then(() => {
          store.dispatch('Messages/invokeFlow', '7e084195c16e-02aeb112e04cd15c-0d45').then();
        });
      }
    });
  }

  @Action
  public onInit(handler: (data: any) => void) {
    window.Quiq.on('init', handler);
  }

  @Action
  public prepareMessage(text: string) {
    window.Quiq.prepareMessage(text, 'replace');
  }

  @Action
  public prependMessage(text: string) {
    window.Quiq.prepareMessage(text, 'prepend');
  }

  @Action
  public appendMessage(text: string) {
    window.Quiq.prepareMessage(text, 'append');
  }

  @Action
  public prepareSnippet(payload: any) {
    window.Quiq.prepareSnippet(payload);
  }

  @Action
  public prepareMessageTemplate(payload: any) {
    window.Quiq.prepareMessageTemplate(payload);
  }

  //Mutations
  @Mutation
  setConversation(conversation: any) {
    this._conversation = conversation;
  }

  @Mutation
  setConversationId(conversationId: string) {
    this._conversationId = conversationId;
  }

  //Next Best Action Calls
}
