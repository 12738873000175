import Vue from 'vue';
import Vuex from 'vuex';
import ToolbarOption from '@/store/modules/ToolbarOption';
import Transcription from '@/store/modules/Transcription';
import NextBestAction from '@/store/modules/NextBestAction';
import Guidance from '@/store/modules/Guidance';
import InteractChatConfig from '@/store/modules/InteractChatConfig';
import Messages from '@/store/modules/Messages';
import InteractEvents from '@/store/modules/InteractEvents';
import DemoConfig from '@/store/modules/DemoConfig';
import InteractAuth from '@/store/modules/InteractAuth';
import QuiqEvents from '@/store/modules/QuiqEvents';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    ToolbarOption,
    Transcription,
    NextBestAction,
    Guidance,
    InteractChatConfig,
    Messages,
    InteractEvents,
    DemoConfig,
    InteractAuth,
    QuiqEvents
  }
});
