import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { EventService } from '@/services/interact/EventService';

@Module({ namespaced: true })
export default class InteractEvents extends VuexModule {
  eventService: EventService | undefined = undefined;

  @Mutation
  setEventService(service: EventService) {
    this.eventService = service;
  }

  @Action
  async addEventService(config: {
    tenantId: string;
    apiKey: string;
    environment: 'Dev' | 'Test' | 'Prod';
  }): Promise<EventService> {
    const es = new EventService(config);
    await es.connect();
    await this.context.commit('setEventService', es);
    return es;
  }
}
