




















































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { WatsonArticle, WatsonDiscoveryService } from '@/services/ibm/WatsonDiscoveryService';
import { InteractConfig } from '@/types';
import { EventService } from '@/services/interact/EventService';

import FuzzySet from 'fuzzyset.js';
import { DemoConfigItem } from '@/services/demo-config.service';
import rdsAPI from '@/api/rdsAPI';
import store from '@/store';

const ToolbarOptionNS = namespace('ToolbarOption');
const InteractEventsNS = namespace('InteractEvents');
const InteractChatConfigNS = namespace('InteractChatConfig');
const DemoConfigNS = namespace('DemoConfig');
const QuiqEvents = namespace('QuiqEvents');

@Component({
  components: {}
})
export default class Knowledge extends Vue {
  loading = false;
  searchService: WatsonDiscoveryService;
  currentSearchResults: Array<WatsonArticle> = new Array<WatsonArticle>();

  currentChatSessionId = '';
  private eventServiceId = '';

  panel = [0];

  color = '#c8c8c8';

  constructor() {
    super();
    this.searchService = new WatsonDiscoveryService();
  }

  @ToolbarOptionNS.Action
  setBadge!: (event: { name: string; badge: number }) => void;

  @InteractEventsNS.Action
  addEventService!: (config: {
    tenantId: string;
    apiKey: string;
    environment: 'Dev' | 'Test' | 'Prod';
  }) => Promise<string>;

  @InteractChatConfigNS.State
  config!: InteractConfig;

  @InteractEventsNS.State
  eventService!: EventService;

  @DemoConfigNS.State
  demoConfigItem!: DemoConfigItem;

  @QuiqEvents.Getter
  conversationId!: string;

  @QuiqEvents.Action
  prepareMessageTemplate!: (payload: any) => void;

  sendKnowledge(article: WatsonArticle) {
    const payload: any = {
      text: article.answer!.join('. '),
      card: {
        title: article.extracted_metadata.title,
        image: {
          publicUrl: 'https://www.callcentrehelper.com/images/stories/2020/03/laptop-turns-into-book-760.jpg'
        }
      }
    };

    if (article.footer && article.footer.length) {
      payload.card.link = {
        url: article.footer[0]
      };
    }

    console.log('Sending message template from knowledge', payload);
    this.prepareMessageTemplate(payload);
  }

  connectEventService(newConfig: InteractConfig) {
    if (!this.eventService) {
      console.warn('No Event Service initialized yet');
      setTimeout(() => {
        this.connectEventService(newConfig);
      }, 500);
    } else {
      this.eventService.subscribe('/' + newConfig.chatSessionId + '/knowledge', (message) => {
        const eventData = JSON.parse(message.body);
        this.search(eventData.data.searchText);
      });
    }
  }

  @Watch('config')
  async onConfigChanged(newConfig: InteractConfig) {
    this.connectEventService(newConfig);
  }

  async search(query: string) {
    console.log(this.config);

    this.loading = true;
    this.currentSearchResults = new Array<WatsonArticle>();
    this.setBadge({
      name: 'Knowledge',
      badge: this.currentSearchResults.length
    });

    try {
      let res = await this.searchService.query(query, this.demoConfigItem);
      debugger;
      res = res.filter((value) => {
        return value.answer != null && value.answer.length > 0;
      });

      // if query === at least one of the file_names without the docx then return all those questions / answers.

      let foundFilenameMatching = false;

      res.map((value) => {
        if (value.extracted_metadata.filename.replace('.docx', '').toLocaleLowerCase() === query.toLocaleLowerCase()) {
          value.extracted_metadata.filename = value.extracted_metadata.filename
            .replace('.docx', '')
            .toLocaleLowerCase();
          foundFilenameMatching = true;
        }
      });

      if (foundFilenameMatching) {
        res = res.filter((value) => {
          return value.extracted_metadata.filename === query.toLocaleLowerCase();
        });
      } else {
        const fuzzy = FuzzySet([query]);
        let foundFuzzyMatch = false;
        const fuzzyThreshold = 0.65;

        res.map((value) => {
          if (value.question) {
            const fuzzyRes = fuzzy.get(value.question[0], [[0]]);
            if (fuzzyRes && fuzzyRes?.length > 0 && fuzzyRes[0].length > 0) {
              value.fuzzyMatch = fuzzyRes[0][0];

              console.log(value.fuzzyMatch);

              if (value.fuzzyMatch >= fuzzyThreshold) {
                foundFuzzyMatch = true;
              }
            }
          } else value.fuzzyMatch = 0;
        });

        res = res.sort((a, b) => {
          if (!b.fuzzyMatch) {
            b.fuzzyMatch = 0;
          }
          if (!a.fuzzyMatch) {
            a.fuzzyMatch = 0;
          }
          return b.fuzzyMatch - a!.fuzzyMatch;
        });

        if (foundFuzzyMatch) {
          res = res.filter((value) => {
            if (value.fuzzyMatch) {
              return value.fuzzyMatch >= fuzzyThreshold;
            } else {
              return false;
            }
          });
        }
      }

      res.map((value, index, array) => {
        if (!value.extracted_metadata.title) {
          if (value.question && value.question.length > 0) {
            value.extracted_metadata.title = value.question[0];
          } else if (!value.extracted_metadata.filename) {
            value.extracted_metadata.title = 'Result ' + (index + 1);
          } else {
            value.extracted_metadata.title = index + 1 + ': ' + value.extracted_metadata.filename.replace('.docx', '');
          }

          return value;
        }
      });

      this.currentSearchResults = res;
    } catch (e) {
      console.error('Error querying watson', e);
    } finally {
      this.loading = false;

      this.setBadge({
        name: 'Knowledge',
        badge: this.currentSearchResults.length
      });

      this.panel = [0];
    }
  }

  panelOpened(article: WatsonArticle) {
    article.viewed = true;
    this.setBadge({
      name: 'Knowledge',
      badge: this.currentSearchResults.filter((value) => {
        return !value.viewed;
      }).length
    });
    rdsAPI().event(store.state.InteractChatConfig.config.chatSessionId, {
      type: 'knowledge_accessed',
      description: 'Knowledge Article Viewed',
      additionalDataTyped: {
        value: article.extracted_metadata.title
      }
    });
  }
}
