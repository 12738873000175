










import { Component, Vue, Watch } from 'vue-property-decorator';
import Toolbar from '@/components/Toolbar.vue';
import ContentDrawer from '@/components/ContentDrawer.vue';
import { namespace } from 'vuex-class';
import { ToolbarOptionEntry } from '@/store/modules/ToolbarOption';
import Tester from '@/components/Tester.vue';
import { InteractConfig } from '@/types';
import { EventService } from '@/services/interact/EventService';
import { NextBestActionEntry } from '@/store/modules/NextBestAction';
import { DemoConfigItem } from '@/services/demo-config.service';
import store from '@/store';
import {VuetifyThemeVariant} from "vuetify/types/services/theme";

const toolbarOption = namespace('ToolbarOption');
const transcription = namespace('Transcription');
const nextBestAction = namespace('NextBestAction');
const interactConfig = namespace('InteractChatConfig');
const InteractEventsNS = namespace('InteractEvents');
const messages = namespace('Messages');
const quiq = namespace('QuiqEvents');

const DemoConfigNS = namespace('DemoConfig');

@Component({
  components: {
    Toolbar,
    ContentDrawer,
    Tester
  }
})
export default class Quiq extends Vue {
  private flexDirection = this.$route.params.side === 'right' ? 'row-reverse' : 'row';

  @DemoConfigNS.State
  demoConfigItem!: DemoConfigItem;

  @DemoConfigNS.Action
  loadConfigById!: (configId: string) => void;

  @toolbarOption.Action
  private pushToolbarOption!: (toolbarOption: ToolbarOptionEntry | ToolbarOptionEntry[]) => void;

  @transcription.Action
  private subscribeToCallminerTranscript!: () => void;

  // used to be nextBestAction
  @quiq.Action
  private subscribeToQuiqAlert!: () => void;

  @interactConfig.Action
  public updateConfig!: (config: InteractConfig) => void;

  @interactConfig.State
  config!: InteractConfig;

  @interactConfig.State
  botId!: string;

  @messages.Action
  public beginChat!: () => void;

  @InteractEventsNS.Action
  addEventService!: (config: {
    tenantId: string;
    apiKey: string;
    environment: 'Dev' | 'Test' | 'Prod';
  }) => Promise<string>;

  @InteractEventsNS.State
  eventService!: EventService;

  @messages.Action
  public addBotResponseOnly!: (botResponseText: string) => void;

  @messages.Action
  public triggerHiddenInput!: (botResponseText: string) => void;

  @messages.Action
  public triggerVisibleInput!: (botResponseText: string) => void;

  @messages.Action
  public addNextBestActionEntry!: (entry: NextBestActionEntry) => void;

  @quiq.Action
  public prepareMessage!: (text: string) => void;

  @quiq.Action
  public prepareMessageTemplate!: (text: string) => void;

  @Watch('botId')
  async onBotIdChanged(botId: string) {
    await this.registerAssistantEvents(botId);
  }

  private async registerAssistantEvents(botId: string) {
    this.eventService.subscribe('/' + botId + '/assistantEvents', (message) => {
      const parsedMessage = JSON.parse(message.body);
      const eventData = parsedMessage.data as {
        eventType:
          | 'trigger_hidden'
          | 'show_response_only'
          | 'trigger_visible'
          | 'next_best_action'
          | 'prepare_message'
          | 'prepare_message_template';
        text: string;
        name?: string;
      };

      switch (eventData.eventType) {
        case 'show_response_only':
          this.addBotResponseOnly(eventData.text);
          break;
        case 'trigger_hidden':
          this.triggerHiddenInput(eventData.text);

          break;
        case 'trigger_visible':
          this.triggerVisibleInput(eventData.text);
          break;
        case 'next_best_action':
          this.addNextBestActionEntry({
            name: eventData.name,
            questionText: eventData.text
          });
          break;
        case 'prepare_message':
          this.prepareMessage(eventData.text);
          break;
        case 'prepare_message_template':
          this.prepareMessageTemplate(JSON.parse(eventData.text));
          break;
        default:
          console.warn('Unknown event type received!', {
            parsedMessage: parsedMessage,
            eventData: eventData
          });
      }
    });
  }

  async waitUntilLoaded() {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (window.Quiq) {
          resolve();
        } else {
          return this.waitUntilLoaded();
        }
      }, 200);
    });
  }

  async mounted() {
    this.$vuetify.theme.themes.light = {
      primary: "#31bf8b",
      secondary: "#424242",
      accent: "#82b1ff",
      error: "#FF5252",
      info: "#2196F3",
      success: "#4CAF50",
      warning: "#FFC107"
    } as VuetifyThemeVariant;

    // TODO - Maybe move this to the store, but then the two next toolbar options
    // would be added anyways, even if the functionality is not yet available.
    console.log('Subscribing to Quiq');
    if (!this.demoConfigItem && this.$route.params.configId) {
      this.loadConfigById(this.$route.params.configId);
    }

    await this.waitUntilLoaded();

    this.subscribeToQuiqAlert();

    await this.addEventService(this.config);

    this.updateConfig((this.$route.query as unknown) as InteractConfig);

    const features = [
      {
        badge: 0,
        icon: 'mdi-book-open-page-variant',
        name: 'Knowledge',
        state: false,
        order: 2
      },
      {
        badge: 0,
        icon: 'mdi-text-subject',
        name: 'Notes',
        state: false,
        order: 4
      },
      {
        badge: 0,
        icon: 'mdi-file-document',
        name: 'Context',
        state: false,
        order: 1
      },
      {
        badge: 0,
        icon: 'mdi-lifebuoy',
        name: 'Next Best Action',
        state: true,
        order: 0
      }
    ];

    if (this.demoConfigItem) {
      console.log(this.demoConfigItem);
      const filteredFeatures = new Array<ToolbarOptionEntry>();

      features.map((value) => {
        if (this.demoConfigItem.features.find((value1) => value1.name === value.name)) {
          filteredFeatures.push(value);
        }
      });

      this.pushToolbarOption(filteredFeatures);

      const config: InteractConfig = {
        domainName:
          this.demoConfigItem.configItems.find((item) => {
            return item.name === 'interact_domain';
          })?.value || '',
        tenantId:
          this.demoConfigItem.configItems.find((item) => {
            return item.name === 'interact_tenant';
          })?.value || '',
        apiKey:
          this.demoConfigItem.configItems.find((item) => {
            return item.name === 'interact_app_key';
          })?.value || '',
        environment: 'Dev',
        mode: 'StandAlone'
      };
      if (config.domainName && config.tenantId && config.apiKey) {
        this.updateConfig(config);
      }
    } else {
      this.pushToolbarOption(features);
    }

    if (window.top === window.self) {
      store.dispatch('Messages/beginChat').then(() => {
        store
          .dispatch('Messages/sendMessageWithVariables', {
            text: 'hello',
            variables: {
              QuiqConversationId: 123
            }
          })
          .then();
      });
    }

    // await callminerRealtimeSpeechService.startListening(
    //   await callminerRealtimeSpeechService.login('tschaefer@jacada.com', 'Jacada44!', 'jacadademo')
    // );
    //
    // this.subscribeToCallminerTranscript();
    //
    // this.subscribeToCallminerAlert();
  }
}
