type Source = 'BOT' | 'USER' | 'BTN' | 'QUIQ';
type ElementType = 'PARAGRAPH' | 'QUESTION_CHOICES_ELEMENT' | 'QUESTION_YES_NO_ELEMENT' | 'QUESTION_INPUT_ELEMENT';
export type MessageType = 'THINKING' | 'STANDARD' | 'GREETING' | 'PAST' | 'TIME' | 'QUIQ' | 'QUIQ_REG';

export type Mode = 'MultiModal' | 'StandAlone' | 'History' | 'LiveChat';

export const thinkingHTML = `<div class="spinner">
                                      <div class="bounce1"></div>
                                      <div class="bounce2"></div>
                                      <div class="bounce3"></div>
                                  </div>`;

export interface Message {
  source: Source;
  timestamp: number;
  type: MessageType;
  html?: string;
  stringMessage?: string;
  pageContent?: Array<InteractPageSection>;
  pageHeader?: InteractPageHeader;
  feedback?: Array<Feedback>;
  flowName?: string;
  responseName?: string;
  quiqRegistrationForm?: Array<RegistrationFormField>;
}

export interface Feedback {
  intentName?: string;
  flowName: string;
  responseName: string;
  feedback: number;
}

export interface InteractConfig {
  chatSessionId?: string;
  chatSessionUniqueId?: string;
  tenantId: string;
  environment: 'Dev' | 'Test' | 'Prod';
  apiKey: string;
  domainName: string;
  mode: Mode;
}

export interface InteractPageHeader {
  id: string;
  innerHtml: string;
}

export interface InteractPageSection {
  id: string;
  elementType: ElementType;
  selectionType: string;
  sectionHeader: {
    id: string;
    innerHtml: string;
  };
  sectionLabel: string;
  sectionChoices: Array<InteractSectionChoice>;
  displayStyle: string;
}

export interface InteractSectionChoice {
  id: string;
  sectionLabel: string;
  choiceValue: string;
  choiceLabel: string;
  referenceName: string;
  choiceOption?: {
    id: string;
    buttonVisible: boolean;
    buttonLabel: string;
  };
  clickToContinueMatrixLayout?: {
    id: string;
    buttonVisible: boolean;
    buttonLabel: string;
    buttonImageName: string;
    isMandatory: boolean;
  };
  clickToContinue?: {
    id: string;
    buttonVisible: boolean;
    buttonLabel: string;
    buttonImageName: string;
    isMandatory: boolean;
  };
  textInput?: {
    id: string;
    buttonVisible: boolean;
    maxCharacters: number;
    numberOfLines: number;
    placeHolderText: string;
    textInputFormat: string;
    textMask: string;
    isMandatory: boolean;
  };
  uploadImageList?: {
    id: string;
    uploadImageListOptions: Array<UploadImageListOptions>;
  };
  ratingChoice?: {
    buttonVisible: boolean;
    maxValue: number;
    value: number;
    choiceType: string;
    ratingItems: Array<any>;
  };
  collectionItem?: {
    Title: string;
    Description: string;
    Image: string;
    buttonImageName: string;
    'Hidden Id': string;
  };
  choiceSwitch?: {
    id: string;
    buttonVisible: boolean;
    yesLabel: string;
    noLabel: string;
  };
  selectFromList?: {
    id: string;
    buttonVisible: boolean;
    selectFromListOptions: Array<{
      id: string;
      buttonVisible: boolean;
      buttonLabel: string;
      buttonImageName: string;
    }>;
  };
}

export interface UploadImageListOptions {
  id: string;
  buttonVisible: boolean;
  shouldUploadToServer: boolean;
  buttonLabel: string;
  photoUrl: string;
}

//Quiq Interfaces
export interface RegistrationFormField {
  id: string;
  required: boolean;
  additionalProperties: {
    isHidden: boolean;
  };
}

// Auth.net Global Callback Func
declare global {
  interface Window {
    onPaymentFormResponse: any;
  }
}

// Theme types
interface ColorSpec {
  primary: string;
  accent: string;
  secondary: string;
  success: string;
  info: string;
  warning: string;
  error: string;
}

export interface Theme {
  name: string;
  light: ColorSpec;
  dark?: ColorSpec;
  logo?: string;
}
